import { jsx } from "react/jsx-runtime";
import { createComponentStore } from "@tecnobit-srl/components-store";
const spinnerContainerStyle = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
};
const {
  Component: InnerContent,
  ComponentStoreProvider: ContentTypesProvider,
  useAddComponent: useAddContentType
} = createComponentStore(
  "content-types",
  /* @__PURE__ */ jsx("div", { style: spinnerContainerStyle })
);
function Content(props) {
  return /* @__PURE__ */ jsx(InnerContent, { id: props.name, p: props });
}
export {
  Content,
  ContentTypesProvider,
  useAddContentType
};
